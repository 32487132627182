import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero/information"

const InformationPage = () => (
  <Layout>
    <Seo title="Unsere Erfahrung" />
    <Hero />
    <section className="section">
      <div className="container">
        <div className="columns">
          <div className="column">
            <StaticImage
              src="../images/informationssicherheit.jpg"
              quality={65}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Clavicon Profile"
              placeholder="tracedSVG"
            />
          </div>
          <div className="column is-three-fifths">
            <div className="content">
              <h3 className="mt-0">
              لا تفوت فرصة الاتصال بنا – حماية مخصصة خصيصا لتقي من الأخطار والتهديدات المتوقعة من الفضاء الالكتروني.
              </h3>
              <p>
              لا يُمكن هذه الأيام تجنب الأضرار بشكل كامل وتقليل المخاطر بشكل مناسب إلا بوضع ما يُسمى بالفضاء الالكتروني في الحسبان. ويسري هذا على الأنظمة المعلوماتية الخاصة بالشركة وأيضًا على تلك الخارجية، فمن الممكن على سبيل المثال أن تؤدي الثغرات في شبكة الشركة الداخلية أو بعض المعلومات الحساسة في الانترنت كذلك إلى نتائج مادية و شخصية بالغة الخطورة.
              </p>              
              <p>
              لدى شركة كلافيكون محللون ومستشارون ذو خبرة في أنظمة المعلومات بإمكانهم تحديد المخاطر والتهديدات ذات الصلة حتى يُمكن التصدي لها بالشكل المناسب.
              </p>
              <p>لمزيد من التفاصيل حول باقة خدماتنا "الأمن المعلوماتي" يُمكنك التواصل معنا في أي وقت لإجراء مقابلة شخصية.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default InformationPage
